<template>
  <div>
    <b-modal
      ref="confirm-approval-modal"
      hide-footer
      :title="confirmAlert.title"
      border-0
      centered
      id="confirm-approval-modal"
    >
      <div class="d-block">
        <b-row class="font-weight-bold text-note">
          <div>
            {{ confirmAlert.text }}
            <span
              type="button"
              class="pl-2 text-primary hover-effect"
              @click="redicrectToApprovalPage()"
              >Xem chi tiết</span
            >
          </div>
        </b-row>
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <b-button
          class="mr-2 btn-width-25"
          variant="secondary"
          @click="hideModal"
          >Trở về</b-button
        >
        <b-button
          variant="danger"
          class="btn-width-25"
          @click="confirmForceCreateApproval()"
          >Xác nhận</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    confirmAlert: {
      type: Object,
      required: true,
    },
    entityId: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    redicrectToApprovalPage() {
      const routeData = this.$router.resolve({
        name: 'list-approval',
        query: { entityId: this.entityId },
      });
      window.open(routeData.href, '_blank');
    },
    showModal() {
      this.$refs['confirm-approval-modal'].show();
    },
    hideModal() {
      this.$refs['confirm-approval-modal'].hide();
    },
    async confirmForceCreateApproval() {
      this.$emit('onClickConfirmForceCreateApproval', true);
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.btn-width-25 {
  width: 25%;
}
.text-note {
  font-size: 14px;
  color: rgb(170 36 36);
}
.hover-effect:hover {
  color: #007bff;
  text-decoration: underline;
}
</style>
